import Iconify from '@/components/iconify';
import { PATH_AUTH, PATH_PAGE } from '@/routes/paths';

const navConfig = [
  {
    title: 'app.homePageMenu.home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'app.homePageMenu.howItWorks',
    icon: <Iconify icon="eva:info-outline" />,
    path: PATH_PAGE.howItWorks,
  },
  {
    title: 'app.homePageMenu.faq',
    icon: <Iconify icon="eva:info-outline" />,
    path: PATH_PAGE.faqs,
  },
  {
    title: 'app.homePageMenu.pricing',
    icon: <Iconify icon="eva:info-outline" />,
    path: PATH_PAGE.pricing,
  },
  {
    title: 'app.homePageMenu.signIn',
    icon: <Iconify icon="eva:log-in-outline" />,
    path: PATH_AUTH.login,
  },
];

export default navConfig;
