import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { isMobile } from 'react-device-detect';
import { WebSocketProvider } from '@/providers/WebSocketProvider';
import { Capacitor } from '@capacitor/core';
import useViewportSize from '../dashboard/useViewportSize';
import Head from 'next/head';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

interface MainLayoutProps {
  children: React.ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
  const { pathname } = useRouter();
  const isHome = pathname === '/';
  const [viewportWidth, viewportHeight] = useViewportSize() || []; // Get the viewport size
  useEffect(() => {
    const viewportMeta = document.getElementById('viewport');
    if (viewportMeta && isMobile) {
      viewportMeta.setAttribute('content', `width=${viewportWidth}, user-scalable=no`);
    }
  }, [viewportWidth, isMobile]);

  return (
    <WebSocketProvider>
      <Head>
        {!isMobile && (
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no" />
        )}
        {isMobile && <meta name="viewport" id="viewport" content={`width=${viewportWidth}, user-scalable=no`} />}
      </Head>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 11 },
          }),
          overflowX: 'auto',
          position: 'relative',
          ...(Capacitor.isNativePlatform() && {
            // minHeight: `${viewportHeight}px`,
            maxHeight: `${viewportHeight}px`,
          }),
          // minHeight: '100vh',
        }}
      >
        {children}
      </Box>

      {!isMobile && <Footer />}
    </WebSocketProvider>
  );
}
